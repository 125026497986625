/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { TeamsContext } from '../context';

export const TeamRemoveOrganizerModal = ({ onClose, title }) => {
  const {
    removeTeamOrganizer,
    refetchTeamDetails,
    team,
    selectedUser,
    teamDetails,
    removeTeamOrganizerLoading,
    removeOrganizerDisc,
  } = useContext(TeamsContext);

  return (
    <Modal {...removeOrganizerDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text width={"96%"}>Remove {getUserFullName(selectedUser)} as Organizer?</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text display="inline">
              {/* {getUserFullName(selectedUser)} will have access to {teamDetails?.name??""} and any team resources within, including all files and
              conversations. */}
              {getUserFullName(selectedUser)} will be removed as an organizer from {teamDetails?.name??""}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => removeOrganizerDisc?.onClose?.()}
            >
              Cancel
            </Button>
            <Button
              isLoading={removeTeamOrganizerLoading}
              variant="primary"
              colorScheme="danger"
              onClick={(e) => {
                removeTeamOrganizer?.(selectedUser)
                  .then(() => refetchTeamDetails?.())
              }}
            >
              Remove Organizer
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
