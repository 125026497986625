import { Button, CloseButton, Spinner, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DeleteIcon2 } from '@mybridge/icons';
import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@mybridge/ui';
import axios from 'axios';
import { useRouter } from 'next/router';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from 'v4/snippets/global/context';
import { TeamsContext } from 'v4/snippets/teams/context';
import { getUserRoomList, removeGroupImage } from 'v4/store/actions/global.data.actions';
import {
  getMyActivity,
  getUserProfileInfo,
} from 'v4/store/actions/user-profile.actions';
export const ImagePopup = forwardRef(({ src, onClose, ...props }, ref) => {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { teamDetails, createOrUpdateTeam, setChatTeamSlug } =
    useContext(TeamsContext);
  const {  pathname } = useRouter();
  const isProfilePage = pathname.includes('/p/');
  const {
    selectedChat,
    setSelectedChat,
    connectionList,
    setConnectionList,
    roomId,
    setRoomId,
    newChatDisc,
  } = useContext(MatrixContext);
  const { imageType } = useContext(GlobalContext);
  console.log(selectedChat, 'selectedChatselectedChatfdgdh');

  const fetchRoomList = async (searchQuery = 'fromUpdateImage') => {
    dispatch(getUserRoomList(searchQuery))
      .then((res) => {
        const rooms = res?.payload?.rooms || [];
        setConnectionList(res?.payload?.rooms || []); // Ensure rooms default to an empty array if undefined

        const totalNotifications = rooms?.reduce(
          (sum, room) => sum + (room?.notification_count || 0),
          0
        );

        console.log(selectedChat, 'selectedChatselectedChat');

        if (searchQuery === 'fromUpdateImage') {
          let openedChat = rooms?.filter(
            (room) => room?.room_id === selectedChat?.room_id
          );
          setSelectedChat(openedChat[0]);
        }
      })
      .finally(() => {});
  };
  const handleRemove = async () => {
    const formData = new FormData();
    if (imageType === 'team-banner') {
      setIsLoading(true);
      const payload = {
        id: teamDetails?.id,
        banner_image: null,
      };
      await createOrUpdateTeam(payload).then((res) => {
        setChatTeamSlug(teamDetails?.slug);
        setIsSuccess(true);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      });
    } else if (imageType === 'team-profile') {
      setIsLoading(true);
      const payload = {
        id: teamDetails?.id,
        image_logo: null,
      };
      await createOrUpdateTeam(payload).then((res) => {
        setChatTeamSlug(teamDetails?.slug);
        setIsSuccess(true);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      });
    } else if (imageType === 'profile-banner') {
      setIsLoading(true);
      formData.append('profile_banner_pic', new Blob(), '');
      axios
        .put('https://api.bybk.org/api/v1/user/profile/info/', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        })
        .then((response) => {
          setIsSuccess(true);
          setIsLoading(false);
          if (onClose) {
            onClose();
          }
        })
        .catch((error) => {
          console.error(
            'Error removing photo:',
            error?.response?.data || error?.message
          );
        });
    } else if (imageType === 'group-profile') {

     dispatch(removeGroupImage(selectedChat?.room_id)).then((res)=>{
      fetchRoomList()
      if (onClose) {
        onClose();
      }
     })
      console.log('remove group profile');
    } else {
      setIsLoading(true);
      formData.append('profile_pic', new Blob(), '');
      axios
        .put('https://api.bybk.org/api/v1/user/profile/info/', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        })
        .then((response) => {
          setIsSuccess(true);
          setIsLoading(false);
          if (onClose) {
            onClose();
          }
        })
        .catch((error) => {
          console.error(
            'Error removing photo:',
            error?.response?.data || error?.message
          );
        });
    }
  };
  const closeSuccessModal = () => {
    setIsSuccess(false);
  };

  useEffect(() => {
    dispatch(getUserProfileInfo());
    dispatch(getMyActivity());
  }, [isSuccess, dispatch]);

  return (
    <Portal>
      <Modal onClose={onClose} size="full" {...props}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>
        </ModalHeader> */}
          <ModalBody
            bgColor="blackAlpha.900"
            overflowY="auto"
            as={HStack}
            justifyContent="center"
            pos="relative"
          >
            <HStack
              pos="absolute"
              justifyContent="space-between"
              zIndex={2}
              left={0}
              right={0}
              top={0}
            >
              <Box display="flex" alignItems="center" gap="15px" p={2}>
                <Image src="/images/logoMb.png" w="8" h="8" />
              </Box>
              {!isProfilePage && (
                isLoading ? (
                  <Spinner size="lg" color="#fff" />
                ) : (
                  <Button
                    title="Remove Profile Pic"
                    padding={0}
                    variant="none"
                    leftIcon={<DeleteIcon2 color="white" />}
                    border={'transparent'}
                    color={'white'}
                    onClick={handleRemove}
                  >
                    Remove Photo
                  </Button>
                )
              )}
              <ModalCloseButton color="#fff" />
            </HStack>
            <Image
              pos="relative"
              zIndex={1}
              bgColor="white"
              src={src}
              maxW="100%"
              maxH="99vh"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccess && (
        <Portal>
          <Modal isOpen={isSuccess}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                display="flex"
                justifyContent="space-between"
                alignItem="center"
              >
                Deleted
                <CloseButton
                  variant={'none'}
                  padding={0}
                  border={0}
                  onClick={closeSuccessModal}
                />
              </ModalHeader>
              <ModalBody>
                {`Your picture has been successfully removed`}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </Portal>
  );
});
