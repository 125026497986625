import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName } from 'v4/lib/commons';
import { inviteUserToChat } from 'v4/store/actions/meeting.actions';
import {
  acceptDiskSubscriptionChangeRequest,
  cancelDiskSubscriptionChangeRequest,
  changeTeamDiskSubscriber,
  getDiskSubscriptionChangeRequests,
} from 'v4/store/actions/myDisk.Action';
import {
  approveGroupPost,
  createGourpPost,
  createTeamCommunity,
  deleteTeam,
  denyTeamJoinRequest,
  getGroupAdminPost,
  getGroupPost,
  getTeamCommunityList,
  getTeamDetailsBySlug,
  getTeamMemberList,
  getTeamOrganiserList,
  inviteUsersToTeam,
  joinTeamRequest,
  leaveTeamCommunity,
  removeTeamMember,
  reportTeamCommunity,
  sendApprovedMessageToGroup,
  updateDiskStatus,
  updateTeamDetails,
  updateTeamMemberStatus,
  withdrawTeamInvite,
} from 'v4/store/actions/teamCommunity.actions';
import { getNextPageFromURL } from 'v4/utils/utils';

export const TeamsContext = createContext({});

/**
 * Teams context hook
 * @param {*} param0
 * @returns
 */
export const useTeamsContext = ({
  slug,
  teamDetailsRefetchEnabled = false,
  teamMembersRefetchEnabled = false,
  teamsListingRefetchEnabled = false,
  teamOrganizersRefetchEnabled = false,
  teamJoinRequestsRefetchEnabled = false,
  teamPendingPostsRefetchEnabled = false,
  teamInvitedUsersRefetchEnabled = false,
  teamBlockedMembersRefetchEnabled = false,
  diskSubscriptionChangeRequestsRefetchEnabled = false,
}) => {
  const [mode, setMode] = useState('create'); // create | edit
  const [team, setTeam] = useState();
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [gifs, setGifs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [mediaUpdated, setMediaUpdated] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [filter, setFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { back, push, pathname } = useRouter();
  const { allDegreeConnections = [], allDegreeConnectionsArr = [] } =
    useSelector((s) => s.connection) ?? {};
  const { userProfileInfo } = useSelector((s) => s.userProfile) ?? {};
  const invitationDisc = useDisclosure();
  const createEditDisc = useDisclosure();
  const leaveTeamDisc = useDisclosure();
  const reportTeamDisc = useDisclosure();
  const removeMemberDisc = useDisclosure();
  const blockTeamMemberDisc = useDisclosure();
  const diskStatusDisc = useDisclosure();
  const makeOrganizerDisc = useDisclosure();
  const removeOrganizerDisc = useDisclosure();
  const deleteTeamDisc = useDisclosure();
  const changeDiskSubscriberDisc = useDisclosure();
  const diskSubscriptionChangeRequestDisc = useDisclosure();
  const [chatRoom, setChatRoom] = useState();
  const [diskStatus, setDiskStatus] = useState();
  const [activeTab, setActiveTab] = useState('Members');
  const myUserId = userProfileInfo?.matrix_user_id;
  const defaultWelcomeNote = 'Welcome to the team, [Name]';
  const [membersLoaded, setMembersLoaded] = useState(false);
  const [organizersLoaded, setOrganizersLoaded] = useState(false);
  const [chatTeamSlug, setChatTeamSlug] = useState(null);
  const audienceDisc = useDisclosure();
  const [permissionValue, setPermissionValue] = useState(null);
  const [currentPermissionValue, setCurrentPermissionValue] = useState(1);
  const [permissionId, setPermissionId] = useState(null);
  const [newTeamJoinRequests, setNewTeamJoinRequests] = useState([]);
  const [errorExistCompany,setErrorExistCompany]=useState(true);
 

  const diskAccessStatuses = useMemo(
    () => ({
      NO: {
        title: 'No disk access',
        color: 'red.500',
      },
      R: {
        title: 'Read access',
        color: '',
      },
      W: {
        title: 'Write access',
        color: 'green.500',
      },
    }),
    []
  );

  const toast = useToast({
    position: 'top',
    status: 'success',
  });
  const textInputRef = useRef();
  const dispatch = useDispatch();

  const { matrixClient, loggedInUser, chatPresets, setTeamsChats } =
    useContext(MatrixContext) ?? {};

  const addImage = (file) => {
    images.push(file);
    setMediaUpdated(true);
    setImages([...images]);
  };

  const removeImage = (file, index) => {
    const images_ = images?.filter((_, ind) => ind !== index);
    setMediaUpdated(true);
    setImages(images_);
  };

  const addGif = (file) => {
    gifs.push(file);
    setMediaUpdated(true);
    setGifs([...gifs]);
  };

  const removeGif = (file, index) => {
    const gifs_ = gifs?.filter((_, ind) => ind !== index);
    setGifs(gifs_);
  };

  const addVideo = (file) => {
    videos.push(file);
    setMediaUpdated(true);
    setVideos([...videos]);
  };

  const removeVideo = (file, index) => {
    const videos_ = videos?.filter((_, ind) => ind !== index);
    setMediaUpdated(true);
    setVideos(videos_);
  };

  const handleFileTypes = (file, type) => {
    if (type === 'file') {
      if (file.type.includes('image')) {
        return 'm.image';
      } else if (file.type.includes('application')) {
        return 'm.file';
      } else if (file.type.includes('audio')) {
        return 'm.audio';
      } else if (file.type.includes('video')) {
        return 'm.video';
      } else {
        return;
      }
    } else {
      if (file.includes('image') || file.includes('gif')) {
        return 'm.image';
      } else if (file.includes('application')) {
        return 'm.file';
      } else if (file.includes('audio')) {
        return 'm.audio';
      } else if (file.includes('video')) {
        return 'm.video';
      } else {
        return;
      }
    }
  };

  // teams listing
  const {
    data: teamsListingPages,
    isFetching: teamsListingFetching,
    isLoading: teamsListingLoading,
    refetch: refetchTeamsListing,
    fetchNextPage: teamsFetchNextPage,
    hasNextPage: teamsHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['teams-listing', { searchQuery, filter }],
    queryFn: async ({ queryKey, pageParam }) => {
      const resp = (
        await dispatch(
          getTeamCommunityList({ pageParam, query: queryKey?.[1] })
        )
      )?.payload;
      return resp;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: teamsListingRefetchEnabled,
  });

  // create team
  const { mutateAsync: createTeamAsync, isLoading: createTeamLoading } =
    useMutation({
      mutationKey: ['create-team'],
      mutationFn: async (team) => {
        const resp = (await dispatch(createTeamCommunity(team)))?.payload;
        if (resp.id) {
          try {
            await matrixClient
              .createRoom({
                visibility: 'private',
                preset: chatPresets.PrivateChat,
                name: `${resp.name}`,
                room_alias_name: `${resp.slug}-team-group`,
                is_direct: false,
                joinRules: 'invite',
                //   initial_state: [
                //     {
                //         type: "m.room.encryption",
                //         state_key: "",
                //         content: {
                //             algorithm: "m.megolm.v1.aes-sha2"
                //         }
                //     }
                // ]
              })
              .then((response) => {
                console.log('Team chat room created:', response.room_id);
                setTeamsChats((prev) => [...prev, response.room_id]);
                if (
                  resp?.image_logo &&
                  !resp?.image_logo?.includes('logo-placeholder-image')
                ) {
                  const content = {
                    url: resp.image_logo,
                  };
                  // Send the new avatar URL as a state event
                  matrixClient
                    .sendStateEvent(response.room_id, 'm.room.avatar', content)
                    .then(() => {
                      console.log('Room avatar changed successfully.');
                      roomChanged(true);
                    })
                    .catch((err) => {
                      console.error('Failed to change room avatar:', err);
                    });
                }

                dispatch(
                  updateTeamDetails({
                    id: resp?.id,
                    matrix_chat_room_id: response?.room_id,
                  })
                );
              })
              .catch((error) => {
                console.error('Error creating room:', error);
              });
          } catch (e) {
            console.error(e);
          }
        }
        return resp;
      },
    });

  //update team
  const { mutateAsync: updateTeamAsync, isLoading: updateTeamLoading } =
    useMutation({
      mutationKey: ['update-team'],
      mutationFn: async (team) => {
        const resp = (await dispatch(updateTeamDetails(team)))?.payload;
        return resp;
      },
    });

  //leave team
  const { mutateAsync: leaveTeamAsync, isLoading: leaveTeamLoading } =
    useMutation({
      mutationKey: ['leave-team'],
      mutationFn: async (team) => {
        const resp = (
          await dispatch(
            leaveTeamCommunity({ for_team_community_id: team?.id })
          )
        )?.payload;
        return resp;
      },
    });

  //report team
  const { mutateAsync: reportTeamAsync, isLoading: reportTeamLoading } =
    useMutation({
      mutationKey: ['report-team'],
      mutationFn: async ({ team, message }) => {
        const resp = (
          await dispatch(
            reportTeamCommunity({
              team: team?.id,
              reported_by: userProfileInfo?.id,
              message: message,
            })
          )
        )?.payload;
        return resp;
      },
    });

  // update member status
  const {
    mutateAsync: updateTeamMemberStatusAsync,
    isLoading: updateTeamMemberStatusLoading,
  } = useMutation({
    mutationKey: ['update-team-member-status'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(updateTeamMemberStatus(payload)))?.payload;
      return resp;
    },
  });

  // remove team member
  const {
    mutateAsync: removeTeamMemberAsync,
    isLoading: removeTeamMemberLoading,
  } = useMutation({
    mutationKey: ['remove-team-member'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(removeTeamMember(payload)))?.payload;
      return resp;
    },
  });

  // update team member's disk access status
  const {
    mutateAsync: updateTeamMemberDiskStatusAsync,
    isLoading: updateTeamMemberDiskStatusLoading,
  } = useMutation({
    mutationKey: ['update-team-member-disk-status'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(updateDiskStatus(payload)))?.payload;
      return resp;
    },
  });

  // join request
  const {
    mutateAsync: requestToJoinTeamAsync,
    isLoading: requestToJoinTeamLoading,
  } = useMutation({
    mutationKey: ['request-to-join-team'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(joinTeamRequest(payload)))?.payload;
      return resp;
    },
  });

  // Deny join request
  const {
    mutateAsync: denyJoinRequestAsync,
    isLoading: denyJoinRequestLoading,
  } = useMutation({
    mutationKey: ['deny-join-request'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(denyTeamJoinRequest(payload)))?.payload;
      return resp;
    },
  });

  // Delete team
  const { mutateAsync: deleteTeamAsync, isLoading: deleteTeamLoading } =
    useMutation({
      mutationKey: ['delete-team'],
      mutationFn: async (payload) => {
        const resp = (await dispatch(deleteTeam(payload)))?.payload;
        return resp;
      },
    });

  // invite user
  const {
    mutateAsync: inviteUserToTeamAsync,
    isLoading: inviteUserToTeamLoading,
  } = useMutation({
    mutationKey: ['invite-user-to-team'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(inviteUsersToTeam(payload)))?.payload;
      return resp;
    },
  });

  // withdraw invite
  const {
    mutateAsync: withdrawTeamInviteAsync,
    isLoading: withdrawTeamInviteLoading,
  } = useMutation({
    mutationKey: ['withdraw-team-invite'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(withdrawTeamInvite(payload)))?.payload;
      return resp;
    },
  });

  // ------
  // teams details
  const {
    data: teamDetails,
    isFetching: teamDetailsFetching,
    isLoading: teamDetailsLoading,
    refetch: refetchTeamDetails,
  } = useQuery(
    ['team-details', chatTeamSlug ? chatTeamSlug : slug],
    async ({ queryKey }) => {
      const resp = (await dispatch(getTeamDetailsBySlug(queryKey?.[1])))
        ?.payload?.[0];
      if (resp?.id) {
        await setTeam(resp);
        try {
          await refetchTeamOrganizers();
          await refetchTeamMembers();
          if (resp?.matrix_chat_room_id) {
            return resp;
            const room = await matrixClient.getRoom(resp?.matrix_chat_room_id);
            if (room) {
              setChatRoom(room);
            }
          } else {
            await matrixClient
              .createRoom({
                visibility: 'private',
                preset: chatPresets.PrivateChat,
                name: `${resp.name}-team-group`,
                room_alias_name: `${resp.slug}-team-group`,
                is_direct: false,
                joinRules: 'invite',
              })
              .then((response) => {
                console.log('Team chat room created:', response.room_id);
                dispatch(
                  updateTeamDetails({
                    id: resp?.id,
                    matrix_chat_room_id: response?.room_id,
                  })
                );
              })
              .catch((error) => {
                console.error('Error creating room:', error);
              });
          }
        } catch (e) {
          console.error(e);
        }
      }
      return resp ?? {};
    },
    {
      refetchOnWindowFocus: false,
      enabled: teamDetailsRefetchEnabled,
    }
  );

  // team members
  const {
    data: teamMembers,
    isFetching: teamMembersFetching,
    isLoading: teamMembersLoading,
    refetch: refetchTeamMembers,
  } = useQuery({
    queryKey: ['team-members', team?.id || teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamMemberList({
              for_team_community_id: queryKey?.[1] || selectedTeam.id,
              list_type: 'member',
            })
          )
        )?.payload?.results ?? [];
      setMembersLoaded(true);
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamMembersRefetchEnabled,
  });

  // team members
  const {
    data: teamLoggedInMember,
    isFetching: teamLoggedInMembersFetching,
    isLoading: teamLoggedInMembersLoading,
    refetch: refetchTeamLoggedInMember,
  } = useQuery({
    queryKey: ['team-members', team?.id || teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamMemberList({
              for_team_community_id: queryKey?.[1],
              list_type: 'member',
              user_id: userProfileInfo?.id,
            })
          )
        )?.payload?.results ?? [];
      setMembersLoaded(true);
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamMembersRefetchEnabled,
  });

  // Join Requests
  const {
    data: teamJoinRequests,
    isFetching: teamJoinRequestsFetching,
    refetch: refetchTeamJoinRequests,
  } = useQuery({
    queryKey: ['team-join-requests', teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamMemberList({
              for_team_community_id: queryKey?.[1] || selectedTeam?.id,
              list_type: 'requested',
            })
          )
        )?.payload?.results ?? [];
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamJoinRequestsRefetchEnabled,
  });

  // Penindg Posts
  const {
    data: teamPendingPosts,
    isFetching: teamPendingPostsFetching,
    refetch: refetchTeamPendingPosts,
  } = useQuery({
    queryKey: ['team-pending-posts', teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      let resp;
      if (isOrganizer) {
        resp =
          (await dispatch(getGroupAdminPost(queryKey?.[1])))?.payload ?? [];
      } else {
        resp = (await dispatch(getGroupPost(queryKey?.[1])))?.payload ?? [];
      }
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamPendingPostsRefetchEnabled,
  });

  // Invited Users
  const {
    data: teamInvitedUsers,
    isFetching: teamInvitedUsersFetching,
    refetch: refetchTeamInvitedUsers,
  } = useQuery({
    queryKey: ['team-invited-users', teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamMemberList({
              for_team_community_id: queryKey?.[1],
              list_type: 'invited',
            })
          )
        )?.payload?.results ?? [];
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamInvitedUsersRefetchEnabled,
  });

  // Blocked Members
  const {
    data: teamBlockedMembers,
    isFetching: teamBlockedMembersFetching,
    refetch: refetchTeamBlockedMembers,
  } = useQuery({
    queryKey: ['team-blocked-members', teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamMemberList({
              for_team_community_id: queryKey?.[1],
              list_type: 'blocked',
            })
          )
        )?.payload?.results ?? [];
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamBlockedMembersRefetchEnabled,
  });
  const {
    data: teamOrganizers,
    isFetching: teamOrganizersFetching,
    refetch: refetchTeamOrganizers,
  } = useQuery({
    queryKey: ['team-organizers', team?.id || teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (
          await dispatch(
            getTeamOrganiserList({
              for_team_community_id: queryKey?.[1],
              list_type: 'organizer',
            })
          )
        )?.payload?.results ?? [];
      setOrganizersLoaded(true);
      return resp;
    },
    refetchOnWindowFocus: false,
    enabled: teamOrganizersRefetchEnabled,
  });

  //create team posts
  const {
    mutateAsync: createTeamPostAsync,
    isLoading: createTeamPostAsyncLoading,
  } = useMutation({
    mutationKey: ['create-team-post'],
    mutationFn: async (post) => {
      console.log('post', post);
      let payload = {};
      {
        post?.file
          ? (payload = {
              for_team_community_id: post?.id,
              message: post?.message,
              file: post?.file,
              message_category: post?.message_category,
            })
          : (payload = {
              for_team_community_id: post?.id,
              message: post?.message,
              message_category: post?.message_category,
            });
      }
      const resp = (await dispatch(createGourpPost(payload)))?.payload;
      return resp;
    },
  });

  // get subscription change requests
  const {
    data: diskSubscriptionChangeRequests,
    isFetching: diskSubscriptionChangeRequestsFetching,
    isLoading: diskSubscriptionChangeRequestsLoading,
    refetch: refetchDiskSubscriptionChangeRequests,
  } = useQuery({
    queryKey: ['disk-subscription-change-requests', teamDetails?.id],
    queryFn: async ({ queryKey }) => {
      const resp =
        (await dispatch(getDiskSubscriptionChangeRequests()))?.payload ?? [];
      return resp;
    },
    refetchOnWindowFocus: true,
    enabled: diskSubscriptionChangeRequestsRefetchEnabled,
  });

  //request for changing team disk subscriber
  const {
    mutateAsync: changeTeamDiskSubscriberAsync,
    isLoading: changeTeamDiskSubscriberLoading,
  } = useMutation({
    mutationKey: ['change-team-disk-subscriber'],
    mutationFn: async (payload) => {
      const resp = (await dispatch(changeTeamDiskSubscriber(payload)))?.payload;
      return resp;
    },
  });

  //cancel request for changing team disk subscriber
  const {
    mutateAsync: cancelDiskSubscriptionChangeRequestAsync,
    isLoading: cancelDiskSubscriptionChangeRequestLoading,
  } = useMutation({
    mutationKey: ['cancel-disk-subscription-change-request'],
    mutationFn: async (payload) => {
      const resp = (
        await dispatch(cancelDiskSubscriptionChangeRequest(payload))
      )?.payload;
      return resp;
    },
  });

  //cancel request for changing team disk subscriber
  const {
    mutateAsync: acceptDiskSubscriptionChangeRequestAsync,
    isLoading: acceptDiskSubscriptionChangeRequestLoading,
  } = useMutation({
    mutationKey: ['accept-disk-subscription-change-request'],
    mutationFn: async (payload) => {
      const resp = (
        await dispatch(acceptDiskSubscriptionChangeRequest(payload))
      )?.payload;
      return resp;
    },
  });

  const acceptDiskSubscriptionChangeRequest_ = async (request) => {
    try {
      const resp = await acceptDiskSubscriptionChangeRequestAsync({
        request_id: request?.id,
      });
      if (resp?.code === 'UPGRADE_PLAN') {
        toast({
          title: 'Upgrade Disk Subscription!',
          status: 'error',
          description:
            'You are having basic disc subscription. Please upgrade plan to standard or professional.',
          duration: 10000,
          isClosable: true,
        });
      } else if (resp?.status_code >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: `Something went wrong!`,
          isClosable: true,
        });
        refetchTeamOrganizers?.();
        refetchTeamDetails?.();
        diskSubscriptionChangeRequestDisc?.onClose();
        refetchDiskSubscriptionChangeRequests();
        changeDiskSubscriberDisc?.onClose();
      } else {
        toast({
          title: 'Success',
          description: `Disk subscriber changed succssfully!`,
          isClosable: true,
        });
        refetchTeamOrganizers?.();
        refetchTeamDetails?.();
        diskSubscriptionChangeRequestDisc?.onClose();
        refetchDiskSubscriptionChangeRequests();
        changeDiskSubscriberDisc?.onClose();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    }
  };

  const cancelDiskSubscriptionChangeRequest_ = async (request) => {
    try {
      // alert(request?.id);
      const resp = await cancelDiskSubscriptionChangeRequestAsync({
        request_id: request?.id,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid subscription change request',
        });
      } else {
        toast({
          title: 'Success',
          description: `Disk subscriber change request withdrawn succssfully!`,
        });
        refetchTeamOrganizers?.();
        refetchTeamDetails?.();
        refetchDiskSubscriptionChangeRequests?.();
        diskSubscriptionChangeRequestDisc?.onClose?.();
        changeDiskSubscriberDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    }
  };

  const createTeamChatPosts = async (post) => {
    try {
      const resp = await createTeamPostAsync(post);
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Success',
          description: `Your post is pending your admin's approval`,
        });
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    }
  };

  const createTeamChatRoom = async ({ team, visibility } = {}) => {
    try {
      const rooms = matrixClient?.getRooms?.() ?? [];
      const exists = rooms?.find?.((r) => r?.name === team?.slug);
      // console.log('existing room', exists);
      if (!exists) {
        const room = await matrixClient?.createRoom?.({
          name: team?.slug,
          visibility,
        });
        console.warn('Created new room:', room);
        setChatRoom(room);
      } else {
        if (exists?.roomId !== chatRoom?.roomId) {
          console.warn('found chat room', exists);
          setChatRoom(exists);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createOrUpdateTeam = async (team) => {
    try {
      let resp;
      if (team?.id) {
        if (typeof team.banner_image === 'string') {
          delete team.banner_image;
        }
        if (typeof team.image_logo === 'string') {
          delete team.image_logo;
        }
        resp = await updateTeamAsync(team);
      } else {
        resp = await createTeamAsync(team);
      }

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Success',
          description: `Team ${team?.id ? 'updated' : 'created'} successfully`,
        });
        if (team?.id) {
          refetchTeamDetails();
        } else {
          refetchTeamsListing();
        }
        createEditDisc?.onClose?.();
        return resp;
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const leaveTeam = async (team, redirectToListing = false) => {
    try {
      const resp = await leaveTeamAsync(team);
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Success',
          description: `Left team successfully`,
        });
        matrixClient?.leave(team?.matrix_chat_room_id);
        if (redirectToListing) {
          back?.();
        } else {
          refetchTeamsListing();
        }
        leaveTeamDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const reportTeam = async (
    team,
  ) => {
    try {

      const resp = await reportTeamAsync(team?.payload);
      if (resp?.status >= 400) {
        setErrorExistCompany(true)
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        setErrorExistCompany(false)
        if (team?.onReport) return
          toast({
            title: 'Success',
            description: `Team reported successfully`,
          });
          if (team?.redirectToListing) {
            back?.();
          } else {
            refetchTeamsListing();
          }
          reportTeamDisc?.onClose?.();
        }
    } catch (e) {
      setErrorExistCompany(true)
      toast({
        title: 'Error',
        status: 'error',
        description: 'Some error occurred',
      });
    } finally {
    }
  };

  const requestToJoinTeam_ = async (team) => {
    // console.log(team);
    try {
      const resp = await requestToJoinTeamAsync({
        for_team_community_id: team?.id,
      });

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Request sent',
          description: `Please wait for the organizers to accept your request.`,
        });
        setNewTeamJoinRequests([...newTeamJoinRequests, teamJoinRequests]);
        refetchTeamJoinRequests?.();
        
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const withdrawRequestToJoinTeam_ = async (member) => {
    try {
      const resp = await denyJoinRequestAsync({
        for_team_community_id: teamDetails?.id || selectedTeam?.id,
        user_id: member?.id,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Request withdrawn',
          description: `Request to join this team has been withdrawn.`,
        });
        setNewTeamJoinRequests([...newTeamJoinRequests, teamJoinRequests]);
        refetchTeamJoinRequests?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const approveTeamJoinRequest_ = async (user) => {
    let welcomeMessage = '';
    const name = `${user.first_name} ${user.last_name}`;
    const regex = /\[Name\]/;
    if (teamDetails?.welcome_note?.length === 0) {
      welcomeMessage = defaultWelcomeNote.replace(regex, `${name}`);
    } else {
      welcomeMessage = teamDetails?.welcome_note.replace(regex, `${name}`);
    }
    // console.log('welcomeMessage', welcomeMessage);
    try {
      const resp = updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: user?.id,
        user_status: 'member',
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Request accepted',
          description: `Team join request has been approved.`,
        });
      }
      refetchTeamJoinRequests?.();
      refetchTeamMembers?.();
      await matrixClient
        ?.invite(
          teamDetails?.matrix_chat_room_id,
          user?.matrix_user_id,
          'team_group_invite'
        )
        .then((response) => {
          if (response) {
            dispatch(
              inviteUserToChat({
                invitee_id: [user?.id],
                sender_id: userProfileInfo?.id,
                room_id: teamDetails?.matrix_chat_room_id,
              })
            ).then((res) => {
              if (res && teamDetails?.is_enable_welcome_note) {
                const content = {
                  body: welcomeMessage,
                  msgtype: 'm.text',
                };
                matrixClient?.sendEvent(
                  teamDetails?.matrix_chat_room_id,
                  'm.room.message',
                  content
                );
              }
            });
          }
        })
        .catch(console.error);
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const approveTeamGroupPost_ = async (id, state, type) => {
    try {
      const resp = await dispatch(
        approveGroupPost({ id: id, status: state })
      ).then((res) => {
        if (res && state === 'accept') {
          let content = {};
          if (type === 'text') {
            content = {
              body: res?.payload?.message,
              msg_type: 'm.text',
            };
          } else {
            content = {
              msg_type: handleFileTypes(
                res.payload.message_category,
                'category'
              ),
              body: 'Image name',
              url: type === 'gif' ? res?.payload?.message : res?.payload?.file,
              media_url:
                type === 'gif' ? res?.payload?.message : res?.payload?.file,
            };
          }
          const body = {
            roomId: teamDetails.matrix_chat_room_id,
            eventType: 'm.room.message',
            userId: res.payload.user,
            content: content,
          };
          dispatch(sendApprovedMessageToGroup(body)).then((res) => {
            console.log('message response', res);
          });
        }
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Some error occured!',
        });
      } else {
        toast({
          title: 'Request accepted',
          description: `Team post updated successfully`,
        });
        refetchTeamPendingPosts?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const denyTeamJoinRequest_ = async (member) => {
    try {
      const resp = await denyJoinRequestAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Request denied',
          description: `Team join request has been denied.`,
        });

        refetchTeamJoinRequests?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const deleteTeam_ = async (team) => {
    try {
      const resp = await deleteTeamAsync(team?.id);
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Could not delete team, try again later!',
        });
        return false;
      } else {
        toast({
          title: 'Team deleted',
          description: `Team was deleted successfully.`,
        });
        return true;
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const withdrawTeamInvite_ = async (member) => {
    try {
      const resp = await withdrawTeamInviteAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Invite withdrawn',
          description: `Invite to join team has been withdrawn.`,
        });

        refetchTeamInvitedUsers?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const blockTeamMember_ = async (member) => {
    try {
      const resp = await updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        user_status: 'blocked',
      });

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'User blocked',
          description: `User has been blocked successfully.`,
        });

        refetchTeamBlockedMembers?.();
        refetchTeamMembers?.();
        blockTeamMemberDisc?.onClose?.();
        try {
          await matrixClient.kick(
            teamDetails?.matrix_chat_room_id,
            member?.user?.matrix_user_id,
            ''
          );
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const acceptInvite = async (member) => {
    let welcomeMessage = '';
    const name = `${member.first_name} ${member.last_name}`;
    const regex = /\[Name\]/;
    if (teamDetails?.welcome_note?.length === 0) {
      welcomeMessage = defaultWelcomeNote.replace(regex, `${name}`);
    } else {
      welcomeMessage = teamDetails?.welcome_note.replace(regex, `${name}`);
    }
    try {
      const resp = await updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        user_status: 'member',
      });
      try {
        // matrixClient.joinRoom(member.roomId);
        matrixClient
          .joinRoom(teamDetails?.matrix_chat_room_id)
          .then(function () {
            console.info('Auto-joined %s', teamDetails?.matrix_chat_room_id);
            setTeamsChats((prev) => [
              ...prev,
              teamDetails?.matrix_chat_room_id,
            ]);
            const content = {
              body: welcomeMessage,
              msg_type: 'm.text',
            };
            const body = {
              roomId: teamDetails.matrix_chat_room_id,
              eventType: 'm.room.message',
              userId: teamDetails?.organizers[0]?.id,
              content: content,
            };
            if (teamDetails?.is_enable_welcome_note) {
              dispatch(sendApprovedMessageToGroup(body)).then((res) => {
                console.log('message response', res);
              });
            }
          });
      } catch (e) {
        console.error('Team chat accept error', e);
        return;
      }
      // matrixClient.on('RoomMember.membership', function (event, member) {
      //   const content = event.getContent();
      //   if (
      //     member.membership === 'invite' &&
      //     member.userId === myUserId
      //      && content?.reason === 'team_group_invite' &&
      //     member.roomId === teamDetails?.matrix_chat_room_id
      //   ) {
      //     console.log('I am here');

      //   }
      // });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Invite accepted',
          description: `You have become part of this team now.`,
        });
        push('/teams/' + teamDetails?.slug);
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
      refetchTeamDetails();
    }
  };

  const unblockTeamMember_ = async (member) => {
    try {
      const resp = await updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        user_status: 'member',
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Member unblocked',
          description: `Team member was unblocked successfully.`,
        });
        refetchTeamBlockedMembers?.();
        refetchTeamMembers?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const makeTeamOrganizer = async (member) => {
    try {
      const resp = await updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        user_status: 'organizer',
      });

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Success',
          description: `Team member has been made organizer successfully.`,
        });
        refetchTeamMembers?.();
        refetchTeamOrganizers?.();
        makeOrganizerDisc?.onClose?.();
        try {
          await matrixClient.setPowerLevel(
            teamDetails?.matrix_chat_room_id,
            member?.user?.matrix_user_id,
            100
          );
          console.log(
            `Made user ${member?.user?.matrix_user_id} admin from the room.`
          );
        } catch (e) {
          console.error('Failed to change user admin:', e);
        }
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const removeTeamOrganizer = async (member) => {
    try {
      const resp = await updateTeamMemberStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        user_status: 'member',
      });

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Success',
          description: `Team member has been removed as an organizer successfully.`,
        });
        refetchTeamMembers?.();
        refetchTeamOrganizers?.();
        removeOrganizerDisc?.onClose?.();
        try {
          await matrixClient.setPowerLevel(
            teamDetails?.matrix_chat_room_id,
            member?.user?.matrix_user_id,
            0
          );
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const inviteUsersToTeam_ = async (member) => {
    const invitedMatrix = _.map(member, 'matrix_user_id');
    const invitedTeam = _.map(member, 'id');
    try {
      const resp = await inviteUserToTeamAsync({
        for_team_community_id: teamDetails?.id,
        invite_user_ids: invitedTeam,
      });
      invitedMatrix.forEach((member) => {
        matrixClient.invite(
          teamDetails?.matrix_chat_room_id,
          member,
          'team_group_invite'
        );
      });

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Invite sent',
          description: `Invite to selected users has been sent successfully.`,
        });

        refetchTeamMembers?.();
        refetchTeamInvitedUsers?.();
        invitationDisc?.onClose?.();
        return resp;
      }
    } catch (e) {
      toast({
        title: 'Invite sent',
        description: `Invite to selected users has been sent successfully.`,
      });
    } finally {
    }
  };

  const removeTeamMember_ = async (member) => {
    try {
      const resp = await removeTeamMemberAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
      });
      try {
        await matrixClient.kick(
          teamDetails?.matrix_chat_room_id,
          member?.matrix_user_id
        );
      } catch (e) {
        console.error(e);
      }

      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Removed',
          description: `Team member has been removed successfully.`,
        });

        refetchTeamMembers?.();
        removeMemberDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const updateTeamMemberDiskStatus_ = async (member, status) => {
    try {
      const resp = await updateTeamMemberDiskStatusAsync({
        for_team_community_id: teamDetails?.id,
        user_id: member?.id,
        disk_status: status,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid team details provided',
        });
      } else {
        toast({
          title: 'Disk access status updated',
          description: `Disk access level has been updated successfully.`,
        });

        refetchTeamMembers?.();
        diskStatusDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const changeTeamDiskSubscriber_ = async (user) => {
    try {
      const resp = await changeTeamDiskSubscriberAsync({
        team_id: teamDetails?.id,
        requested_to: user?.user?.id,
      });
      if (resp?.status >= 400) {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Invalid request',
        });
      } else {
        if (resp?.message?.includes?.('already sent')) {
          return resp;
        } else {
          toast({
            title: 'Request sent successfully!',
            description: `Disk subscription change request has been sent to ${getUserFullName(
              user
            )}.`,
          });
        }

        refetchTeamOrganizers?.();
        refetchTeamDetails?.();
        changeDiskSubscriberDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
    }
  };

  const teamsListing = useMemo(() => {
    return [].concat.apply(
      [],
      teamsListingPages?.pages?.map((p) => p?.results ?? [])
    );
  }, [teamsListingPages]);

  const isOrganizer = useMemo(() => {
    return teamOrganizers?.find?.((tm) => tm?.user?.id === userProfileInfo?.id);
  }, [userProfileInfo, teamOrganizers]);

  const isMember = useMemo(() => {
    return (
      isOrganizer ||
      teamMembers?.find?.((tm) => tm?.user?.id === userProfileInfo?.id)
    );
  }, [userProfileInfo, teamMembers, isOrganizer]);

  const isInvited = useMemo(() => {
    return teamInvitedUsers?.find?.(
      (tm) => tm?.user?.id === userProfileInfo?.id
    );
  }, [userProfileInfo, teamInvitedUsers]);

  const isRequested = useMemo(() => {
    return teamJoinRequests?.find?.(
      (tm) => tm?.user?.id === userProfileInfo?.id
    );
  }, [userProfileInfo, teamJoinRequests]);

  const currentDiskAccess = useMemo(() => {
    const members = [...(teamMembers ?? []), ...(teamOrganizers ?? [])];
    const me = members?.find?.((tm) => tm?.user?.id === userProfileInfo?.id);
    return me?.disk_status;
  }, [userProfileInfo, teamMembers, teamOrganizers]);

  const isUserOrganizer = (member) => {
    return teamOrganizers?.find?.((tm) => tm?.user?.id === member?.id);
  };

  const isUserMember = (member) => {
    return teamMembers?.find?.((tm) => tm?.user?.id === member?.id);
  };

  const isUserInvited = (member) => {
    return teamInvitedUsers?.find?.((tm) => tm?.user?.id === member?.id);
  };

  const isUserRequested = (member) => {
    return teamJoinRequests?.find?.((tm) => tm?.user?.id === member?.id);
  };

  const myDiskAccessType = useMemo(() => {
    if (teamLoggedInMember && teamDetails) {
      // return teamLoggedInMember[0]?.disk_status;
      return [...(teamOrganizers ?? []), ...(teamLoggedInMember ?? [])]?.find?.(
        (tm) => tm?.user?.id === userProfileInfo?.id
      )?.disk_status;
    } else {
      refetchTeamLoggedInMember();
    }
  }, [teamMembers, teamOrganizers, teamLoggedInMember]);

  // useEffect(() => {
  //   console.warn(matrixClient);
  //   console.warn(matrixClient?.getRooms?.());
  // }, [matrixClient]);
  // useEffect(() => {
  //   // console.log(matrixClient?.getRooms?.());
  //   if (matrixClient && loggedInUser && teamDetails?.matrix_chat_room_id) {
  //     const room = matrixClient?.getRoom(teamDetails?.matrix_chat_room_id);
  //     if (room) {
  //       setChatRoom(room);
  //     }
  //   }
  // }, [matrixClient, loggedInUser, teamDetails]);

  useEffect(() => {
    refetchTeamsListing();
  }, [searchQuery, filter]);

  useEffect(() => {
    refetchTeamDetails();
    // refetchTeamOrganizers()
    refetchDiskSubscriptionChangeRequests()
  }, [chatTeamSlug]);

  useEffect(() => {
    // refetchTeamDetails();
    refetchTeamOrganizers()
    refetchDiskSubscriptionChangeRequests()
  }, [teamDetails]);
  useEffect(() => {
    if(teamDetails?.id && pathname?.includes('members')) {
      refetchTeamPendingPosts()
    }
  },[teamDetails?.id, pathname])

  const diskOrganizer = teamOrganizers?.find?.(
    (o) => o?.user?.id === teamDetails?.disk_payer
  );

  return {
    diskOrganizer,
    createEditDisc,
    leaveTeamDisc,
    reportTeamDisc,
    invitationDisc,
    removeMemberDisc,
    diskStatusDisc,
    makeOrganizerDisc,
    removeOrganizerDisc,
    deleteTeamDisc,
    changeDiskSubscriberDisc,
    diskSubscriptionChangeRequestDisc,
    mode,
    setMode,
    allDegreeConnections,
    allDegreeConnectionsArr,
    slug,
    userProfileInfo,
    isMember,
    isOrganizer,
    isInvited,
    isRequested,
    text,
    setText,
    images,
    setImages,
    videos,
    setVideos,
    gifs,
    setGifs,
    textInputRef,
    addImage,
    removeImage,
    addVideo,
    removeVideo,
    addGif,
    removeGif,
    expanded,
    setExpanded,
    defaultWelcomeNote,

    // chat room
    createTeamChatRoom,
    chatRoom,
    setChatRoom,
    createTeamChatPosts,
    createTeamPostAsyncLoading,

    //pending post for chat
    refetchTeamPendingPosts,
    teamPendingPosts,
    teamPendingPostsFetching,
    approveTeamGroupPost: approveTeamGroupPost_,
    //team
    team,
    setTeam,
    createOrUpdateTeam,
    createOrUpdateTeamLoading: createTeamLoading || updateTeamLoading,

    // members
    teamMembers,
    teamMembersFetching,
    teamMembersLoading,
    refetchTeamMembers,

    // organizers
    teamOrganizers,
    teamOrganizersFetching,
    refetchTeamOrganizers,
    makeTeamOrganizer,
    makeTeamOrganizerLoading: updateTeamMemberStatusLoading,
    removeTeamOrganizer,
    removeTeamOrganizerLoading: updateTeamMemberStatusLoading,

    //requests
    newTeamJoinRequests,
    setNewTeamJoinRequests,
    teamJoinRequests,
    refetchTeamJoinRequests,
    teamJoinRequestsFetching,
    requestToJoinTeam: requestToJoinTeam_,
    requestToJoinTeamLoading,
    approveTeamJoinRequest: approveTeamJoinRequest_,
    approveTeamJoinRequestLoading: updateTeamMemberStatusLoading,
    denyTeamJoinRequest: denyTeamJoinRequest_,
    denyTeamJoinRequestLoading: denyJoinRequestLoading,
    withdrawRequestToJoinTeam: withdrawRequestToJoinTeam_,
    withdrawRequestToJoinTeamLoading: denyJoinRequestLoading,

    //invited
    teamInvitedUsers,
    teamInvitedUsersFetching,
    refetchTeamInvitedUsers,
    withdrawTeamInvite: withdrawTeamInvite_,
    withdrawTeamInviteLoading,
    inviteUsersToTeam: inviteUsersToTeam_,
    inviteUserToTeamLoading,
    acceptInvite,
    acceptInviteLoading: updateTeamMemberStatusLoading,

    //blocked
    teamBlockedMembers,
    teamBlockedMembersFetching,
    refetchTeamBlockedMembers,
    blockTeamMember: blockTeamMember_,
    blockTeamMemberLoading: updateTeamMemberStatusLoading,
    unblockTeamMember: unblockTeamMember_,
    unblockTeamMemberLoading: updateTeamMemberStatusLoading,

    // team details
    teamDetails,
    teamDetailsFetching,
    teamDetailsLoading,
    refetchTeamDetails,

    // listing
    teamsListing,
    teamsListingFetching,
    teamsListingLoading,
    refetchTeamsListing,
    teamsFetchNextPage,
    teamsHasNextPage,
    teamsTotalCount: teamsListingPages?.pages?.length * 10,
    teamsListingPages,
    audienceDisc,
    permissionValue,
    setPermissionValue,
    currentPermissionValue,
    setCurrentPermissionValue,
    permissionId,
    setPermissionId,

    // update team member status
    // : organizer / member / blocked / remove etc
    updateTeamMemberStatus,
    updateTeamMemberStatusLoading,
    removeTeamMember: removeTeamMember_,
    removeTeamMemberLoading,

    // disk status
    updateTeamMemberDiskStatus: updateTeamMemberDiskStatus_,
    updateTeamMemberDiskStatusLoading,

    //leave
    leaveTeam,
    leaveTeamLoading,

    //report
    reportTeam,
    errorExistCompany,
    reportTeamLoading,

    //selected
    selectedUser,
    setSelectedUser,

    selectedTeam,
    setSelectedTeam,

    activeTab,
    setActiveTab,

    isUserOrganizer,
    isUserMember,
    isUserInvited,
    isUserRequested,
    diskAccessStatuses,
    diskStatus,
    setDiskStatus,

    blockTeamMemberDisc,
    currentDiskAccess,
    deleteTeam: deleteTeam_,
    deleteTeamLoading,

    diskSubscriptionChangeRequests,
    diskSubscriptionChangeRequestsFetching,
    diskSubscriptionChangeRequestsLoading,
    refetchDiskSubscriptionChangeRequests,
    changeTeamDiskSubscriber: changeTeamDiskSubscriber_,
    changeTeamDiskSubscriberLoading,
    cancelDiskSubscriptionChangeRequest: cancelDiskSubscriptionChangeRequest_,
    cancelDiskSubscriptionChangeRequestLoading,
    acceptDiskSubscriptionChangeRequest: acceptDiskSubscriptionChangeRequest_,
    acceptDiskSubscriptionChangeRequestLoading,
    myDiskAccessType,

    membersLoaded,
    organizersLoaded,

    teamLoggedInMember,
    teamLoggedInMembersFetching,
    teamLoggedInMembersLoading,
    refetchTeamLoggedInMember,
    handleFileTypes,
    chatTeamSlug,
    setChatTeamSlug,
    searchQuery,
    setSearchQuery,
    filter,
    setFilter,
  };
};
