import {
  NetworkChatIcon,
  FollowIcon,
  FollowingIcon,
  HandShakeSimpleIcon,  
} from '@mybridge/icons';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import { HStack, IconButton, Tooltip, Button } from '@mybridge/ui';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { forwardRef, useContext } from 'react';
import { MyNetworkContext } from '../network/context';
import {MyNetworkCircleCardMenu} from "../../../mybridgev4/snippets/profile/network/circle-menu";
export const UserNetworkingActions = forwardRef(
  ({ user, size, showHandshakeOnly,fromOnboarding=false,...props }, ref) => {
    const networkCtx = useContext(MyNetworkContext);
    const { startChatSingle } = useContext(MatrixContext);
    const { startMeetingSingle } = useContext(MatrixContext);
    const {
      isFollowed,
      isFriend,
      followUser,
      handshakeUser,
      handshakeLoading,
      followLoading,
      isHandshaked,
      setSelectedUser,
      selectedUser,
      handleHandshakeSendRevoke,
      revokeHandshakeLoading,
    } = networkCtx ?? {};


    console.log(isFriend?.(user),"userrrrrrrrrr");
    return isFriend?.(user) ? (
      <HStack {...props}>
        <Tooltip label={'Chat'}>
          <IconButton
            onClick={(e) => {
              startChatSingle?.(user);
            }}
            bg="none"
              width="30px"
              height="40px"
              borderRadius="50%"
              border="1px solid #00000033"
            size={size ? size : 'md'}
          >
            <NetworkChatIcon
              width={size === 'sm' ? 8 : 20}
              height={size === 'sm' ? 8 : 20}
            />
          </IconButton>
        </Tooltip>
        <Tooltip label={'Meeting'}>
          <IconButton
            onClick={(e) => {
              startMeetingSingle?.(user);
            }}
            bg="none"
              width="30px"
              height="40px"
              borderRadius="50%"
              border="1px solid #00000033"
            size={size ? size : 'md'}
          >
            <MeetingDefaultIcon
              width={size === 'sm' ? 8 : 20}
              height={size === 'sm' ? 8 : 20}
            />
          </IconButton>
        </Tooltip>
        <MyNetworkCircleCardMenu fromOnboarding={fromOnboarding} user={user} />
      </HStack>
    ) : (
      <HStack {...props}>
        {showHandshakeOnly ? (
          <>
            {isHandshaked?.(user) ? (
              <>
              <Tooltip label="Withdraw Handshake">
                <IconButton
                  onClick={(e) => {
                    setSelectedUser?.(user);
                    handleHandshakeSendRevoke?.(user);
                  }}
                  bg="none"
              width="40px"
              height="40px"
              borderRadius="50%"
              border="1px solid #00000033"
                  isLoading={
                    revokeHandshakeLoading && selectedUser?.id === user?.id
                  }
                  size={size ? size : 'md'}
                  icon={
                    <HandShakeSimpleIcon
                      color="rgb(179, 140, 140)"
                      width={20}
                      height={20}
                    />
                  }
                />
              </Tooltip>
              <MyNetworkCircleCardMenu fromOnboarding={fromOnboarding} user={user} />
            </>
            ) : (
              <>
              <Tooltip label="Send Handshake Requests">
                <IconButton
                  onClick={(e) => {
                    setSelectedUser?.(user);
                    handshakeUser?.(user,fromOnboarding?"onboarding":null);
                  }}
                  bg="none"
              width="40px"
              height="40px"
              borderRadius="50%"
              border="1px solid #00000033"
                  isLoading={handshakeLoading && selectedUser?.id === user?.id}
                  size={size ? size : 'md'}
                  // isDisabled={user?.field_audience_visibility?.WHO_CAN_SEE_FRIENDS === 1 ? false : true}
                  icon={
                    <HandShakeSimpleIcon
                      {...(isHandshaked?.(user) ? {} : { color: 'rgb(148, 176, 150)' })}
                      width={20}
                      height={20}
                    />
                  }
                />
              </Tooltip>
              <MyNetworkCircleCardMenu fromOnboarding={fromOnboarding} user={user} />
            </>
            )}
          </>
        ) : (
          <>
            <Tooltip label={isFollowed?.(user) ? 'Following' : 'Follow'}>
              <IconButton
                isDisabled={isFollowed?.(user)}
                onClick={(e) => {
                  setSelectedUser?.(user);
                  followUser?.(user);
                }}
                // variant={isFollowed?.(user) ? 'secondary' : 'secondary'}
                isLoading={followLoading}
                size={size ? size : 'md'}
                w={'41px'}
                height={'41px'}
                border={'1px solid #00000033'}
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius={'50%'}
                variant="none"
                _hover={{
                  bg: 'gray.100',
                }}
              >
                <FollowIcon
                  // {...(isFollowed?.(user) ? {} : { color: 'white' })}
                  width={20}
                  height={20}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              label={isHandshaked?.(user) ? 'Handshake sent' : 'Handshake'}
            >
              <IconButton
                onClick={(e) => {
                  setSelectedUser?.(user);
                  handshakeUser?.(user,fromOnboarding?"onboarding":null);
                }}
                isLoading={handshakeLoading&&user?.id===selectedUser?.id}
                // isDisabled={isHandshakeRequested?.(user)}
                
                icon={
                  <HandShakeSimpleIcon
                    {...(isHandshaked?.(user) ? {color: 'rgb(179, 140, 140)'} : { color: 'rgb(148, 176, 150)' })}
                    width="20"
                    height="20"
                  />
                }
                w={'41px'}
                height={'41px'}
                border={'1px solid #00000033'}
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius={'50%'}
                variant="none"
                _hover={{
                  bg: 'gray.100',
                }}
              />

              {/* <IconButton
                isDisabled={isHandshaked?.(user)}
                onClick={(e) => {
                  setSelectedUser?.(user);
                  handshakeUser?.(user);
                }}
                variant="secondary"
                isLoading={handshakeLoading}
                size={size ? size : 'md'}
              >
                <HandShakeSimpleIcon
                  {...(isHandshaked?.(user) ? {} : { color: 'green' })}
                  width={20}
                  height={20}
                />
              </IconButton> */}
            </Tooltip>
          </>
        )}
      </HStack>
    );
  }
);
