/* eslint-disable @nx/enforce-module-boundaries */
import { LocationIcon } from '@mybridge/icons';
import { Spinner } from '@mybridge/ui';
import { useDisclosure, useOutsideClick } from '@mybridge/ui/hooks';
import { Input, InputGroup, InputLeftElement } from '@mybridge/ui/input';
import { List, ListItem } from '@mybridge/ui/list';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { useEffect, useRef, useState } from 'react';
import { formatLocation, formatCommaSeparated } from 'v4/lib/commons';
import { useCityStateCountry } from 'v4/lib/hooks/use-city-state-country';
import { useCountries } from 'v4/lib/hooks/use-countries';
import styles from '../../../mybridgev4/snippets/jobs/search-bar/index.module.scss';
import { border, Box, FormControl, Portal, VStack } from '@chakra-ui/react';

export const CityStateCountryDropdown = ({
  value,
  defaultValue,
  onChange,
  leftIcon,
  noIcon = false,
  countryCode,
  clearOnSelect = false,
  countryVisible = true,
  inputGroupProps,
  ...props
}) => {
  const inputRef = useRef();
  const containerRef = useRef();
  const disc = useDisclosure();
  const [selected, setSelected] = useState(defaultValue);
  const [search, setSearch] = useState(
    clearOnSelect ? '' : formatLocation(defaultValue, { countryVisible })
  );
  const { data, isFetching, refetch } = useCityStateCountry({
    search,
    countryCode,
  });
  const { countries } = useCountries({
    search,
  });

  const handleSearch = () => {
    if (search?.length) {
      disc?.onOpen?.();
      refetch?.();
    }
  };

  useEffect(() => {
    if (value) {
      const str = formatLocation(value, { countryVisible });
      if (!clearOnSelect && search !== str && !search?.length) {
        setSearch(str);
      }
    }
  }, [value]);

  const handleSelect = (item) => (e) => {
    // console.log(item);
    setSelected(item);
    const str = formatLocation(item, { countryVisible });
    // console.log(str);
    onChange?.(item);
    setSearch(str);
    disc?.onClose?.();
  };

  // console.log(value);
  // console.log(countryCode);

  return (
    <Popover
      {...disc}
      matchWidth={true}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <InputGroup {...inputGroupProps}>
          {noIcon ? (
            ''
          ) : (
            <InputLeftElement>
              {isFetching ? (
                <Spinner size="sm" />
              ) : (
                leftIcon ?? <LocationIcon />
              )}
            </InputLeftElement>
          )}
          <Input
            onKeyUp={handleSearch}
            pl={noIcon ? '' : 10}
            ref={inputRef}
            value={search}
            onClick={(e) => disc?.onOpen?.()}
            onChange={(e) => {
              setSearch(e.target.value);
              disc?.onOpen?.();
            }}
            placeholder="Type to search..."
            autoComplete="off"
            borderRadius="full"
            bg="brandGray.500"
            _focus={{ border: 'none' }}
            {...props}
          />
        </InputGroup>
      </PopoverTrigger>
      {disc?.isOpen && search?.length > 0 && (data?.pages?.[0]?.length || countries?.length) ? (
        <Portal>
          <PopoverContent width={"100%"} maxWidth={"284px"} className={styles.searchPopUp} top={"0px"} left={"1px"}>
            <PopoverBody style={{ outline: 'none', borderRadius: '80px', padding: "0" }}>
              <FormControl width="100%">
                <InputGroup>
                  <InputLeftElement>
                    <LocationIcon />
                  </InputLeftElement>
                  <Input
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Type to search..."
                    pl={12}
                    borderRadius="full"
                    bg="transparent"
                    border="0"
                  />
                </InputGroup>
              </FormControl>
              <Box className={styles.searchPopUpBottomLine} />
              <VStack maxH={"400px"} height={"100%"} overflow={"auto"} align="stretch" className={styles.searchGroup}>
                <List>
                  {countries?.length > 0 && countries?.map?.((country, countryIndex) => (
                    <ListItem
                      key={countryIndex}
                      _hover={{ bg: 'gray.100' }}
                      px={4}
                      py={2}
                      onClick={handleSelect({ country: country?.name })}
                    >
                      {country?.name}
                    </ListItem>
                  ))}
                  {data?.pages?.[0]?.map?.((c, cind) => (
                    <ListItem
                      key={cind}
                      _hover={{ bg: 'gray.100' }}
                      px={4}
                      py={2}
                      onClick={handleSelect(c)}
                    >
                      {formatLocation(c)}
                    </ListItem>
                  ))}
                </List>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      ) : null}
    </Popover>
  );
};
